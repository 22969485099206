import './Intro.css';
import Container from '@material-ui/core/Container';
import React, { useRef } from "react";

function Intro() {
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })   

  return (
    <div>
      <div className="home-panel" id="test">
        <div className="container">
          {/* <div>
                    <img src="assets/images/icons/Pedim.png">
                </div>
                <div>
                    <p className="email">pedim@pedim.com.br</p>
                </div> */}

          <div>
            <h1 className="name">{'{'} Pedro Fernandez {'}'}</h1>
            <h2 className="name-subtitle">full stack web developer and game enthusiast</h2>
          </div>

          <div className='about-me'>
            {/* <p (click)="scroll(aboutMe)" style="color: white; cursor: pointer; font-family: cherry-monospace-light;">More about me!</p> */}
            <p onClick={executeScroll} className='about-me-text'>More about me!</p>
          </div>

          <div className="mt-2">
            <a className="p-10" href="https://github.com/pedroeste" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/github_transparent_white.png')} className="icon" alt="" />
            </a>
            <a className="p-10" href="https://www.instagram.com/pedro.efernandez/" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/instagram_transparent_white.png')} className="icon" alt="" />
            </a>
            <a className="p-10" href="https://www.linkedin.com/in/pedro-fernandez-543816116/" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/linkedin_transparent_white.png')} className="icon" alt="" />
            </a>
          </div>
        </div>
      </div>

      <div className="description" ref={myRef}>
        <div className="container">
          <Container>
            <h3>ABOUT ME</h3>
            <p>
                My name is Pedro Fernandez, I hold a degree in Computer Science 
                from Anhembi Morumbi University and have experience in web development. 
                I've worked with .NET Core, Python, Node.js, and GraphQL. Currently, 
                I focus on front-end development, mainly using Angular. Additionally, 
                I’m enhancing my backend skills by studying Java with Spring Boot.
            </p>
          </Container>
        </div>
      </div>

      <div className="experiences">
        <div className="container">
          <Container>
            <h3>EXPERIENCES</h3>

            <div className="experience mb-5">
                <h4 className='company'>Accenture</h4>
                <p className="job-title">Frontend Developer - March, 2023 - Now</p>
                <p className="job-description">Development and integration of new features for the Banco Santander's managers' dashboard. Utilizing Angular as the main technology.</p>
            </div>
            <div className="experience mb-5">
                <h4 className='company'>Banco Safra</h4>
                <p className="job-title">Fullstack Developer - December, 2021 - December, 2022</p>
                <p className="job-description">Development of web applications for the SafraPay team.</p>
            </div>
            <div className="experience mb-5">
                <h4 className='company'>MJV Technology & Innovation Brasil</h4>
                <p className="job-title">Fullstack Developer - June, 2019 - November, 2021</p>
                <p className="job-description">Development of web applications with Angular 8+, Node.js and GraphQL using Jenkins as CI & CD.</p>
            </div>
            <div className="experience mb-5">
                <h4 className='company'>Austin Associados</h4>
                <p className="job-title">Junior Fullstack Developer - February, 2019 - June, 2019</p>
                <p className="job-description">Development of applications with .NET Core to assist all of the consultants.</p>
            </div>
            <div className="experience mb-5">
                <h4 className='company'>Mastertech</h4>
                <p className="job-title">Junior Fullstack Developer - February, 2018 - December, 2019</p>
                <p className="job-description">Development of web applications with Python and Django and sometimes C#</p>
            </div>
          </Container>
        </div>
      </div>

      <div className="projects">
          <div className="container">
            <Container>
              <h3>PROJECTS</h3>

              <div className="experience mb-5">
                  <h4 className='company'>
                      Bagres FC 
                      <a href="https://www.bagresfc.com.br/" target="_blank" rel="noopener noreferrer">
                          <img src={require('../../assets/images/icons/link.png')} className="icon space-left" alt='' />
                      </a>
                  </h4>
                  <p className='project-description'>Bagres FC is a fantasy game created in collaboration with a friend, where I was able to apply the knowledge gained from courses and research related to Java Spring Boot.</p>
              </div>
              <div className="experience mb-5">
                  <h4 className='company'>
                      Finite Automaton  
                      <a href="https://github.com/pedroeste/trabalho-pratico-afd" target="_blank" rel="noopener noreferrer">
                          <img src={require('../../assets/images/icons/github_transparent.png')} className="icon space-left" alt='' />
                      </a>
                  </h4>
                  <p className='project-description'>College activity that shows every step of an finite automaton.</p>
              </div>
              <div className="experience mb-5">
                  <h4 className='company'>
                      Tests Manager API
                      <a href="https://github.com/pedroeste/PergunteMeAPI" target="_blank" rel="noopener noreferrer">
                          <img src={require('../../assets/images/icons/github_transparent.png')} className="icon space-left" alt='' />
                      </a>
                  </h4>
                  <p className='project-description'>This is my final paper project, it's a API that we used to manage and create all the tests that my college would apply.</p>
              </div>
            </Container>
          </div>
        </div>
      </div>

  );
}

export default Intro;
